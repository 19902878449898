import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import MyModal from "./components/HistoryModal";
import DeleteAlertModal from "./components/DeleteAlertModal";
import { oddController } from "../controllers/oddsController/oddController";
import ReactiveButton from 'reactive-button';
import { Button } from 'react-bootstrap';
import color from "../config/color";
import Loading from "./components/Loading";
import { useTranslation } from "react-i18next";


function History(props) {
  const {theme} = props;
  const userInfo=JSON.parse(localStorage.getItem("USR"));
  const { t, i18n } = useTranslation('global');
  const [isLoading, setLoading] = useState(false);
  const [item, setItem] = useState([]);
  const [itemdetails, setItemdetails] = useState([]);
  const [isEdit, setIsEdit] = useState("");
  const [amount, setAmount] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [goal, setGoal] = useState(0);
  const [unit, setUnit] = useState(0);
  const [selectedCustomer, setSelectdCustomer] = useState(0);
  const [deleteId, setDeleteId] = useState(0);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);
  const [itemview, setItemview] = useState({
    "voucher": "",
    "amount": 0,
    "status": "",
    "event": "",
    "eventMyan": "",
    "color": "",
    "bettingId": 0,
    "eventTime": "",
    "leagueName": "",
    "result": "",
    "odds": "",
    "customerId": 0,
    "customerName": "",
    "bet": "",
    "bettedDate": "",
  })

  useEffect(() => {
    getMemberOutstanding();
    getCustomer();
  }, []);

  const getMemberOutstanding = () => {
    setLoading(true);
    oddController.getOutstanding(parseInt(userInfo.oddsUserId), (data) => {
      const totalAmt = data.historydata.reduce((accumulator, current) => accumulator + current.totalAmount, 0);
      setItem(data.historydata);
      setTotal(totalAmt);
      setItemdetails(data.historydetails);
      setLoading(false);
    });
  };

  const handleClick = (index) => {
    let newitem = [...item];
    newitem[index].isExpand = !newitem[index].isExpand;

    setItem(newitem);
  };

  const handleUpdate = (bettingid) => {
    const tempGoal = goal == 0 ? '=' : goal.toString();
    const tempUnit = goal > 0 ? unit == 0 ? '+0' : unit > 0 ? '+' + unit.toString() : unit.toString() : goal == 0 && unit == 0 ? 'D' : unit.toString();
    const oddsUpdated = tempGoal + tempUnit;

    oddController.updateOutstanding(bettingid, amount, oddsUpdated, selectedCustomer, (data) => {
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getMemberOutstanding();
    });
  }

  const handleRemoveVoucher = () => {
    oddController.removeOutstanding(deleteId, (data) => {
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getMemberOutstanding();
    });
  }

  const handleChangeGoal = (value) => {
    if (value <= 12) {
      setGoal(value);
    }
  };

  const handleClickUnit = (type) => {
    if (type == "inc") {
      setUnit(prev => prev + 5)
    } else {
      setUnit(prev => prev - 5)
    }
  }

  const getCustomer = () => {
    oddController.getCustomer(parseInt(userInfo.oddsUserId), (data) => {
      setCustomer(data.customer);
    });
  }

  const handleOnClick = (value) => {
    setTab(value);
    // if (value){
    //    setGoalbtnColor('btn-activate');
    //    setBodybtnColor('btn-normal');
    // }else{
    //    setGoalbtnColor('btn-normal');
    //    setBodybtnColor('btn-activate');
    // }
  }

  return (
    <div>
      <DeleteAlertModal handleConfirm={handleRemoveVoucher} t={t} description={"deleteConfirm"}/>
      <MyModal
        isEdit={isEdit}
        historydata={itemview}
        amount={amount}
        setAmount={setAmount}
        customer={customer}
        selectedCustomer={selectedCustomer}
        unit={unit}
        goal={goal}
        handleClickUnit={handleClickUnit}
        handleChangeGoal={handleChangeGoal}
        setSelectdCustomer={setSelectdCustomer}
        handleUpdate={handleUpdate}
        t={t}
        i18n={i18n}
      />
      <span className="site-header" style={{ color: color[theme].main }}>{t('outstandingTitle')}</span>
      {/* <div className="d-flex justify-content-center mb-2">
        <div className="btn-group" role="group" aria-label="Basic example">
        <button type="button" 
        className="btn-normal"
        style={{backgroundColor:tab === "list" ? color[theme].secondary : color[theme].secondary3,fontSize:'0.8rem' }} 
        onClick={() => handleOnClick("list")}
        >
          {t('listMode')}
        </button>
        <button type="button" 
        className="btn-normal" 
        style={{backgroundColor:tab === "edit" ? color[theme].secondary : color[theme].secondary3,fontSize:'0.8rem' }} 
        onClick={() => handleOnClick("edit")}
        >
          {t('editMode')}
        </button>
        </div>
      </div>  */}
      <div className="d-flex justify-content-center mb-2">
        <div
          style={{
            borderBottomStyle: tab === "list" ? 'solid' : null,
            borderBottomWidth: tab === "list" ? 3 : null,
            borderBottomColor: tab === "list" ? color[theme].main : null,
            fontSize: '0.8rem',
            marginRight: 6,
            cursor: 'pointer'
          }}
          onClick={() => handleOnClick("list")}
        >
          <span><i className="fa fa-eye me-1"></i>{t('listMode')}</span>
        </div>
        <span>{" / "}</span>
        <div
          style={{
            borderBottomStyle: tab === "edit" ? 'solid' : null,
            borderBottomWidth: tab === "edit" ? 3 : null,
            borderBottomColor: tab === "edit" ? color[theme].main : null,
            fontSize: '0.8rem',
            marginLeft: 6,
            cursor: 'pointer'
          }}
          onClick={() => handleOnClick("edit")}
        >
          <span><i className="fa fa-edit me-1"></i>{t('editMode')}</span>
        </div>
      </div>
      {tab === "edit" ?
        <Edit customer={customer} t={t} i18n={i18n} theme={theme}/> :
        <div>
          {isLoading ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Loading />
              <p>Loading .....</p>
            </div>
          ) : (
            <div>
              <div className="wrapper">
                <div style={{ display: 'flex', justifyContent: 'start', marginBottom: 3 }}>
                  <span
                    style={{
                      background: '#dddddd87',
                      padding: '10px 10px',
                      fontSize: 'small',
                      color: 'black',
                      borderRadius: 5
                    }}
                  >Total Bet Amt - {total}</span>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr style={{ fontSize: '0.85rem', backgroundColor: color[theme].headerbg }}>
                        <th scope="col"></th>
                        <th scope="col">{t('no')}</th>
                        <th scope="col"></th>
                        <th scope="col">{t('username')}</th>
                        <th scope="col">{t('amount')}</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {/* <tbody>
                  {data &&
                    data.map((d, i) => {
                      return (
                        <tr key={d.gamblingId}>
                          <th scope="row">{i + 1}</th>
                          <td>{d.voucher}</td>
                          <td>2022-10-19 07:00:00</td>
                          <td>{d.username}</td>
                          <td>{d.amount}</td>
                          <td>
                            <button
                              className="btn btn-outline-success"
                              style={{ marginRight: "5px" }}
                              data-bs-toggle="modal"
                              data-bs-target="#myModal"  
                              onClick={()=>setIsEdit("View")}
                            >
                              <i className="fas fa-eye"></i>&nbsp;View
                            </button>
                            <button
                              className="btn btn-outline-success"
                              data-bs-toggle="modal"
                              style={{ marginRight: "5px" }}
                              data-bs-target="#myModal"
                              onClick={()=>setIsEdit("Edit")}
                            >
                              <i className="fas fa-edit"></i>&nbsp;Edit
                            </button>
                            <button
                              className="btn btn-outline-success"
                              data-bs-toggle="modal"
                              data-bs-target="#deletealertModal"
                              onClick={()=>setIsEdit("Edit")}
                            >
                              <i className="fas fa-trash"></i>&nbsp;Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody> */}
                    <tbody style={{ fontSize: '0.8rem' }}>
                      {item.length != 0 ?
                        item &&
                        item.map((d, i) => {
                          return (
                            <Fragment key={i}>
                              <tr >
                                <td>
                                  <a onClick={() => handleClick(i)} style={{ marginLeft: '5%', cursor: 'pointer' }}>
                                    {d.isExpand ? (
                                      <i className="fas fa-chevron-up"></i>
                                    ) : <i className="fas fa-chevron-down"></i>}
                                  </a>
                                </td>
                                <th scope="row">{i + 1}</th>
                                <td></td>
                                <td>{d.customerName}</td>
                                <td>{d.totalAmount}</td>
                                <td></td>
                                <td></td>

                              </tr>
                              {d.isExpand ? (
                                <ExpandRow
                                  setItemview={setItemview}
                                  customerId={d.customerId}
                                  itemview={itemview}
                                  itemdetails={itemdetails}
                                  setAmount={setAmount}
                                  setSelectdCustomer={setSelectdCustomer}
                                  setGoal={setGoal}
                                  setUnit={setUnit}
                                  setDeleteId={setDeleteId}
                                  setIsEdit={setIsEdit}
                                  t={t}
                                  i18n={i18n}
                                  theme={theme}
                                />
                              ) : null}
                            </Fragment>
                          );
                        })
                        :
                        <tr>
                          <td colSpan={7} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
}

export default History;

export function ExpandRow({
  itemdetails,
  customerId,
  setIsEdit,
  setItemview,
  itemview,
  setAmount,
  setSelectdCustomer,
  setUnit,
  setGoal,
  setDeleteId,
  t,
  i18n,
  theme
}) {
  //const result = data.filter(a=>a.CustomerId == customerId);

  var result = itemdetails.filter((el) => {
    return el.customerId == customerId
  }
  );

  //console.log("expand data", result);
  //console.log("expand data id", customerId);
  //console.log("expand data data", itemdetails);

  const handleViewModal = (type, result) => {
    const newdata = { ...itemview };

    newdata["voucher"] = result.voucher;
    newdata["amount"] = result.amount;
    newdata["status"] = result.status;
    newdata["event"] = result.event;
    newdata["eventMyan"] = result.eventMyan;
    newdata["color"] = result.color;
    newdata["bettingId"] = result.bettingId;
    newdata["eventTime"] = result.eventTime;
    newdata["leagueName"] = result.leagueName;
    newdata["result"] = result.result;
    newdata["odds"] = result.odds;
    newdata["customerId"] = result.customerId;
    newdata["customerName"] = result.customerName;
    newdata["bet"] = result.bet;
    newdata["bettedDate"] = result.bettedDate;
    setItemview(newdata);

    if (type == "View") {
      setIsEdit("View");
    } else {
      setIsEdit("Edit")
    }

    setAmount(result.amount);
    setSelectdCustomer(result.customerId);

    const isExist = result.odds.includes("=");
    let tempGoal = 0;
    let tempUnit = 0;

    if (isExist) {
      const arr = result.odds.split(/[=]/);
      tempGoal = 0;
      tempUnit = arr[1] == 'D' ? 0 : parseInt(arr[1]);
      setGoal(tempGoal);
      setUnit(tempUnit);
    }
    else {
      const isExist_plus = result.odds.includes("+");

      if (isExist_plus) {
        const arr = result.odds.split(/[+]/);
        tempGoal = arr[0];
        tempUnit = parseInt(arr[1]);
        setGoal(tempGoal);
        setUnit(tempUnit);
      }
      else {
        const arr = result.odds.split(/[-]/);
        tempGoal = arr[0];
        tempUnit = -1 * parseInt(arr[1]);
        setGoal(tempGoal);
        setUnit(tempUnit);
      }

    }
  }

  return (
    <>
      <tr style={{ fontSize: '0.85rem', backgroundColor: color[theme].headerbg }}>
        <th></th>
        <th scope="col">{t('no')}</th>
        <th scope="col">{t('bettedDate')}</th>
        <th scope="col">{t('choice')}</th>
        <th scope="col">{t('amount')}</th>
        <th scope="col">{t('odds')}</th>
        <th scope="col">{t('action')}</th>
      </tr>
      {result &&
        result.map((d, i) => {
          return (
            <Fragment key={d.bettingId}>
              <tr className="table-secondary" style={{ fontSize: '0.8rem' }}>
                <td></td>
                <th scope="row">{i + 1}</th>
                <td>
                  <span>{`${moment(d.bettedDate).format("DD-MM-YYYY hh:mma")}`}</span>
                </td>

                <td><span style={{ color: d.color }}>{i18n.language === "mm" ? d.betMyan : d.bet}</span></td>
                <td>{d.amount}</td>
                <td>{d.odds}</td>
                <td>
                  <div className="d-flex">
                    {/* <button
                      className="btn btn-outline-success"
                      style={{ marginRight: "5px" }}
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                      onClick={() => handleViewModal("View", d)}
                    >
                      <i className="fas fa-eye"></i>&nbsp;View
                    </button> */}
                    <button
                      className="btn"
                      data-bs-toggle="modal"
                      style={{ marginRight: "5px", fontSize: '0.8rem', backgroundColor: color[theme].main, color: '#fff' }}
                      data-bs-target="#myModal"
                      onClick={() => handleViewModal("Edit", d)}
                    >
                      <i className="fas fa-edit my-icon"></i>&nbsp;{t('edit')}
                    </button>
                    <button
                      className="btn"
                      data-bs-toggle="modal"
                      style={{ fontSize: '0.8rem', backgroundColor: color[theme].main, color: '#fff' }}
                      data-bs-target="#deletealertModal"
                      onClick={() => setDeleteId(d.bettingId)}
                    >
                      <i className="fas fa-trash my-icon"></i>&nbsp;{t('delete')}
                    </button>
                  </div>
                </td>
              </tr>
            </Fragment>
          );
        })}
    </>
  );
}

const Edit = (props) => {
  const { customer, t, i18n,theme } = props;
  const [voucherList, setVoucherList] = useState([]);
  const [editableData, setEditableData] = useState([]);
  const [valueState, setValueState] = useState('idle');
  const [searchedCustomer,setSearchedCustomer] = useState([]);
 const [searchText, setSearchText] = useState([]);

  useEffect(() => {
    setSearchedCustomer(customer);
  }, [])

  const handleVoucherView = (customerId) => {
    setEditableData([]);
    //console.log("customer",customerId)
    oddController.getOverallVoucher(parseInt(customerId), (data) => {
      setVoucherList(data.payload);
    });
  }

  const handleVoucherAdd = (v) => {
    //console.log("ddd",editableData)
    const arr = editableData.map(obj => ({ ...obj }));
    arr.push(v);
    //const arr = [...editableData];
    //arr.push(v);
    setEditableData(arr);
  }

  const handleVoucherSave = () => {
    setValueState('loading');
    oddController.updateOverallVoucher(editableData, (data) => {
      setValueState('success');
      setVoucherList([]);
      setEditableData([]);
    });
  }

  const handleTextChange = (index, value, type) => {
    let newData = [...editableData];
    if (type === 'odds') {
      editableData[index].odds = value;
    }
    if (type === 'amount') {
      editableData[index].amount = value;
    }
    setEditableData(newData);
  };

  const handleVoucherRemove = (value) => {
    let newData = [...editableData];
    newData = newData.filter(item => item.bettingId != value.bettingId);
    setEditableData(newData);
  }

  const handleCustomerChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length != 0) {
        const filteredRows = customer.filter((row) => {
            return (
                row['customerName'].toLowerCase().includes(e.target.value.toLowerCase())
            );
        });
        setSearchedCustomer(filteredRows);
    }
    else {
      setSearchedCustomer(customer);
    }
}

  return (
    <div>
      <div className="mb-2">
        <input
          type="text"
          value={searchText}
          onChange={(e) => handleCustomerChange(e)}
          className="custom-input"
          style={{ height: 35, width: 200, fontSize: '0.8rem' }}
          id="exampleFormControlInput1"
          placeholder="search ..."
        />
      </div>
      <div className="row">
        <div className="col-lg-3 col-12 mb-4">
          <div className="bg-light history-container" style={{ height: 400, overflowY: 'scroll', boxShadow: theme === "dark" && "#565c5c 0px 1px 5px" }}>
            <table className="table">
              <thead style={{ position: 'sticky',top: 0, fontSize: '0.87rem', backgroundColor: color[theme].headerbg }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('name')}</th>
                  <th scope="col">{t('action')}</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '0.8rem' }}>
                {searchedCustomer && searchedCustomer.map((v, i) =>
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{v.customerName}</td>
                    <td>
                      <Button style={{ backgroundColor: color[theme].main, borderColor: color[theme].main }} size="sm" onClick={() => handleVoucherView(v.customerId)}>
                        <i className="fa fa-eye"></i>
                      </Button>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-4 col-12 mb-2">
          <div className="bg-light history-container" style={{height: 400, overflowY: 'scroll',boxShadow: theme === "dark" && "#565c5c 0px 0px 5px"}}>
            <table className="table table-hover">
              <thead style={{ position: 'sticky',top: 0,fontSize: '0.87rem', backgroundColor: color[theme].headerbg }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('choice')}</th>
                  <th scope="col">{t('odds')}</th>
                  <th scope="col">{t('amount')}</th>
                  <th scope="col">{t('action')}</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '0.8rem' }}>
                {voucherList.length > 0 ? voucherList.map((v, i) =>
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{i18n.language === "mm" ? v.choiceMyan : v.choice}</td>
                    <td>{v.odds}</td>
                    <td>{v.amount}</td>
                    <td>
                      <Button size="sm" onClick={() => handleVoucherAdd(v)} style={{ backgroundColor: color[theme].main, color: '#fff' }}>
                        <i className="fa fa-plus"></i>
                      </Button>
                    </td>
                  </tr>
                ) : <tr>
                  <td colSpan={5} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                </tr>
                }
                {/* <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td><Button variant="success" size="sm">Add</Button></td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry the Bird</td>
                <td>@twitter</td>
                <td>@mdo</td>
                <td><Button variant="success" size="sm">Add</Button></td>
              </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-lg-5 col-12 mb-2">
          <div className="bg-light" style={{boxShadow: theme === "dark" && "#565c5c 0px 0px 5px"}}>
            <table className="table">
              <thead style={{ fontSize: '0.87rem', backgroundColor: color[theme].headerbg }}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t('choice')}</th>
                  <th scope="col">{t('odds')}</th>
                  <th scope="col">{t('amount')}</th>
                  <th scope="col">{t('action')}</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: '0.8rem' }}>
                {editableData.length > 0 ? editableData.map((v, i) =>
                  <tr key={i}>
                    <th scope="row">{i + 1}</th>
                    <td>{i18n.language === "mm" ? v.choiceMyan : v.choice}</td>
                    <td>
                      <input
                        type="text"
                        className="custom-input"
                        value={v.odds}
                        style={{ height: 30, fontSize: '0.8rem', width: 60 }}
                        onChange={(e) =>
                          handleTextChange(i, e.target.value, 'odds', v)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="custom-input"
                        value={v.amount}
                        style={{ height: 30, fontSize: '0.8rem', width: 60 }}
                        onChange={(e) =>
                          handleTextChange(i, e.target.value, 'amount', v)
                        }
                      />
                    </td>
                    <td>
                      <Button variant="danger" size="sm" onClick={() => handleVoucherRemove(v)}>
                        <i className="fa fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ) :
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                  </tr>
                }
              </tbody>
            </table>
            <div className="d-flex justify-content-center pb-2">
              <ReactiveButton
                buttonState={valueState}
                idleText={t('save')}
                style={{ backgroundColor: color[theme].main, color: '#fff' }}
                loadingText={t('loading')}
                successText={t('successText')}
                disabled={editableData.length > 0 ? false : true}
                onClick={() => handleVoucherSave()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
