import React, { Fragment, useEffect, useState } from 'react'
import moment from "moment";
import ReactPaginate from "react-paginate";
import { oddController } from "../controllers/oddsController/oddController";
import AnalysisModal from './components/AnalysisModal';
import color from '../config/color';
import Loading from './components/Loading';
import PurchaseBettingModal from './components/PurchaseBettingModal';

const BodyLiveData = (props) => {
    const { t, i18n, theme } = props;
    const userInfo=JSON.parse(localStorage.getItem("USR"));
    const defaultDate = moment(new Date()).format("YYYY-MM-DD");
    const [startDate, setStartDate] = useState(defaultDate);
    const rowsPerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState();
    const [searchText, setSearchText] = useState([]);
    const [searchSingle, setSearchSingle] = useState([]);
    const [singleLiveData, setSingleLiveData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [rapidEventId, setRapidEventId] = useState(0);
    const [eventList, setEventList] = useState([]);
    const limitAmount = userInfo.limitAmount;

    useEffect(() => {
        getGetBodyLiveData();
    }, []);

    const getGetBodyLiveData = () => {
        setLoading(true);
        oddController.getSingleLiveData(startDate, true, parseInt(userInfo.oddsUserId), (data) => {
            setSearchSingle(data.livedata);
            setSingleLiveData(data.livedata);
            setPage(data.livedata.length / rowsPerPage);
            setLoading(false);
        });
    }

    const handlePageClick = async (data) => {
        setPageCount(data.selected);
    };

    const handleTeamChange = (e) => {
        setSearchText(e.target.value);
        const columnNameHome = i18n.language === "mm" ? 'homeTeamMyan' : 'homeTeam';
        const columnNameAway = i18n.language === "mm" ? 'awayTeamMyan' : 'awayTeam';
        if (e.target.value.length != 0) {
            const filteredRows = searchSingle.filter((row) => {
                return (
                    row[columnNameHome].toLowerCase().includes(e.target.value.toLowerCase()) ||
                    row[columnNameAway].toLowerCase().includes(e.target.value.toLowerCase())
                );
            });
            setSearchSingle(filteredRows);
            setPage(filteredRows.length / rowsPerPage);
        }
        else {
            setSearchSingle(singleLiveData);
            setPage(singleLiveData.length / rowsPerPage);
        }
    }

    const handleBettingPurchase = (d, type, max) => {
        let obj = {};

        if (type === "bodyDiff") {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeam: d.homeTeam,
                homeTeamMyan: d.homeTeamMyan,
                awayTeamMyan: d.awayTeamMyan,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate: d.selectedDate,
                isFivePercentCom: d.isFivePercentCom,
                choiceBetType: "Body",
                bodyOdds: d.bodyOdds,
                goalOdds: d.goalOdds,
                choiceTeam: d.maxBody === d.homeTeam ? "Home" : "Away",
                amount: d.bodyAmount
            }
        }
        else if (type === "goalDiff") {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeamMyan: d.homeTeamMyan,
                awayTeamMyan: d.awayTeamMyan,
                homeTeam: d.homeTeam,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate: d.selectedDate,
                isFivePercentCom: d.isFivePercentCom,
                choiceBetType: "Goal",
                bodyOdds: d.bodyOdds,
                goalOdds: d.goalOdds,
                choiceTeam: d.maxGoal,
                amount: d.goalAmount
            }
        }
        else {
            obj = {
                rapidEventId: d.rapidId,
                homeTeamId: d.homeTeamId,
                awayTeamId: d.awayTeamId,
                homeTeamMyan: d.homeTeamMyan,
                awayTeamMyan: d.awayTeamMyan,
                homeTeam: d.homeTeam,
                awayTeam: d.awayTeam,
                underId: d.underTeamId,
                overId: d.overTeamId,
                selectedDate: d.selectedDate,
                bodyOdds: d.bodyOdds,
                goalOdds: d.goalOdds,
                isFivePercentCom: d.isFivePercentCom,
            }
        }

        setEventList([obj]);
    }

    return (
        <div>
            <PurchaseBettingModal
                t={t}
                userId={userInfo.oddsUserId}
                eventList={eventList}
                editData={null}
                seteditData={null}
                i18n={i18n}
                handleFetch={getGetBodyLiveData}
                theme={theme}
            />
            <AnalysisModal
                rapidEventId={rapidEventId}
                t={t}
                userId={userInfo.oddsUserId}
                theme={theme}
            />
            <div className="mb-2 d-flex justify-content-between align-items-center flex-wrap" style={{ gap: 5 }}>
                <div className="">
                    <input
                        className="custom-input"
                        type="date"
                        id="birthday"
                        style={{ fontSize: '0.8rem', height: 32, width: 150 }}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        name="birthday"
                    />
                    <button
                        type="button"
                        className="btn"
                        style={{ backgroundColor: color[theme].main, color: '#fff', fontSize: '0.8rem', marginLeft: 5 }}
                        onClick={() => getGetBodyLiveData()}
                    >
                        {t('search')}
                    </button>
                </div>
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => handleTeamChange(e)}
                    className="custom-input"
                    style={{ height: 35, width: 200, fontSize: '0.8rem' }}
                    id="exampleFormControlInput1"
                    placeholder="search ..."
                />
            </div>
            {isLoading ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Loading />
                    <p>Loading .....</p>
                </div>
            ) : (
                <Fragment>
                    <div className="table-responsive">
                        <table className="report-table table">
                            <thead style={{ fontSize: '0.85rem', backgroundColor: color[theme].headerbg }}>
                                <tr>
                                    <th scope="col">{t('no')}</th>
                                    <th scope="col">{t('eventTime')}</th>
                                    <th scope="col">{t('team')}</th>
                                    <th scope="col"></th>
                                    <th scope="col">{t('homeAmt')}</th>
                                    <th scope="col">{t('awayAmt')}</th>
                                    <th scope="col">{t('overAmt')}</th>
                                    <th scope="col">{t('underAmt')}</th>
                                    <th scope="col">{t('bodyDiff')}</th>
                                    <th scope="col">{t('goalDiff')}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody style={{ fontSize: '0.8rem' }}>
                                {searchSingle.length != 0 ?
                                    searchSingle &&
                                    searchSingle
                                        .slice(
                                            pageCount * rowsPerPage,
                                            pageCount * rowsPerPage + rowsPerPage
                                        )
                                        .map((d, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <tr >
                                                        <th scope="row">{pageCount * rowsPerPage + i + 1}</th>
                                                        <td style={{width:100,whiteSpace: 'nowrap', wordWrap: 'break-word'}}>
                                                            <div className="d-flex flex-column">
                                                            <span>{moment(d.eventTime).format("DD-MM-YYYY")}</span>
                                                            <span>{moment(d.eventTime).format("hh:mm A")}</span>
                                                            </div> 
                                                        </td>
                                                        <td style={{width:300,whiteSpace: 'nowrap', wordWrap: 'break-word'}}>
                                                            <span style={{ color: d.overTeamId === d.homeTeamId ? color[theme].secondary6 : null, marginRight: 5 }}>
                                                                {i18n.language === "mm" ? d.homeTeamMyan : d.homeTeam}
                                                            </span>
                                                            Vs
                                                            <span style={{ color: d.overTeamId === d.awayTeamId ? color[theme].secondary6 : null, marginLeft: 5 }}>
                                                                {i18n.language === "mm" ? d.awayTeamMyan : d.awayTeam}
                                                            </span> <br />
                                                            ({d.bodyOdds})/({d.goalOdds})
                                                        </td>
                                                        <td style={{width:80,whiteSpace: 'nowrap', wordWrap: 'break-word'}}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', fontSize: 10, gap: 5 }}>
                                                                <span>({t('sellAmt')})</span>
                                                                {userInfo.usePurchaseMdule ? <span>({t('buyAmt')})</span> : null}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.homeAmount > d.awayAmount ? color[theme].secondary6 : 'black' }}>
                                                                    {d.homeAmount.toLocaleString("en-US")}
                                                                </span>
                                                                {userInfo.usePurchaseMdule ?
                                                                    <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                        {d.purHomeAmount === 0 ? '-' : d.purHomeAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.awayAmount > d.homeAmount ? color[theme].secondary6 : 'black' }}>
                                                                    {d.awayAmount.toLocaleString("en-US")}
                                                                </span>
                                                                {userInfo.usePurchaseMdule ?
                                                                    <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                        {d.purAwayAmount === 0 ? '-' : d.purAwayAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.over > d.under ? color[theme].secondary6 : 'black' }}>
                                                                    {d.over.toLocaleString("en-US")}
                                                                </span>
                                                                {userInfo.usePurchaseMdule ?
                                                                    <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                        {d.purOverAmount === 0 ? '-' : d.purOverAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span style={{ color: d.under > d.over ? color[theme].secondary6 : 'black' }}>
                                                                    {d.under.toLocaleString("en-US")}
                                                                </span>
                                                                {userInfo.usePurchaseMdule ?
                                                                    <span style={{ color: 'black', fontSize: '0.7rem' }}>
                                                                        {d.purUnderAmount === 0 ? '-' : d.purUnderAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        <td style={{ color: color[theme].secondary5, fontWeight: 'bold' }}>
                                                            {
                                                                userInfo.usePurchaseMdule ? d.bodyAmount <= limitAmount ?
                                                                    <span>
                                                                        {d.bodyAmount === 0 ? "-" : i18n.language === "mm" ? d.maxBodyMyan : d.maxBody}<br />
                                                                        {d.bodyAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : <span
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => handleBettingPurchase(d, "bodyDiff")}
                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                    >
                                                                        {i18n.language === "mm" ? d.maxBodyMyan : d.maxBody}<br />
                                                                        {d.bodyAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        {d.bodyAmount === 0 ? "-" : i18n.language === "mm" ? d.maxBodyMyan : d.maxBody}<br />
                                                                        {d.bodyAmount.toLocaleString("en-US")}
                                                                    </span>

                                                            }
                                                        </td>
                                                        <td style={{ color: color[theme].secondary5, fontWeight: 'bold' }}>
                                                            {
                                                                userInfo.usePurchaseMdule ? d.goalAmount <= limitAmount ?
                                                                    <span>
                                                                        { d.goalAmount === 0 ? "-" :  i18n.language === "mm" ? d.maxGoalMyan : d.maxGoal}<br />
                                                                        {d.goalAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    : <span
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        data-bs-toggle="modal"
                                                                        onClick={() => handleBettingPurchase(d, "goalDiff")}
                                                                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                                    >
                                                                        {i18n.language === "mm" ? d.maxGoalMyan : d.maxGoal}<br />
                                                                        {d.goalAmount.toLocaleString("en-US")}
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        {d.goalAmount === 0 ? "-" : i18n.language === "mm" ? d.maxGoalMyan : d.maxGoal}<br />
                                                                        {d.goalAmount.toLocaleString("en-US")}
                                                                    </span>
                                                            }
                                                        </td>
                                                        <td style={{width:200,whiteSpace: 'nowrap', wordWrap: 'break-word'}}>
                                                            {
                                                                userInfo.usePurchaseMdule ?
                                                                    <button
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        data-bs-toggle="modal"
                                                                        style={{ backgroundColor: color[theme].main, color: '#fff', marginRight: 5 }}
                                                                        className='btn btn-sm mt-2'
                                                                        onClick={() => handleBettingPurchase(d)}>
                                                                        <i className="fa-solid fa-upload my-icon"></i>&nbsp;{t('purchase')}
                                                                    </button> : null
                                                            }
                                                            <button
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#analysisModal"
                                                                style={{ backgroundColor: color[theme].main, color: '#fff' }}
                                                                className='btn btn-sm mt-2'
                                                                onClick={() => setRapidEventId(d.rapidId)}>
                                                                <i className="fa fa-eye my-icon"></i>&nbsp;{t('view')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                        })
                                    : <tr>
                                        <td colSpan={9} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <ReactPaginate
                        previousLabel={t('previous')}
                        nextLabel={t('next')}
                        breakLabel={"..."}
                        pageCount={page}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </Fragment>
            )}
        </div>
    )
}

export default BodyLiveData;


