import Analysis from "../Page/Analysis";
import Betting from "../Page/Betting";
import BettingPurchase from "../Page/BettingPurchase";
import Calculate from "../Page/Calculate";
import Customer from "../Page/Customer";
import History from "../Page/History";
import LogIn from "../Page/LogIn";
import OddsTransfer from "../Page/OddsTransfer";
import Report from "../Page/Report";

export const routeName = {
    loginRoute: "/login",
    oddTransferRoute: "/odds",
    bettingRoute: "/betting",
    reportRoute: "/report",
    outstandingRoute: "/history",
    calculateRoute: "/calculate",
    customerRoute:"/customer",
    analysisRoute:"/analysis",
    purchaseRoute:"/purchase"
}

const routes = [
    {
        path: routeName.loginRoute,
        component: LogIn
    },
    {
        path: routeName.oddTransferRoute,
        component: OddsTransfer
    },
    {
        path: routeName.bettingRoute,
        component: Betting
    },
    {
        path: routeName.reportRoute,
        component: Report
    },
    {
        path: routeName.outstandingRoute,
        component: History
    },
    {
        path: routeName.calculateRoute,
        component: Calculate
    },
    {
        path: routeName.customerRoute,
        component: Customer
    },
    {
        path: routeName.analysisRoute,
        component: Analysis
    },
    {
        path: routeName.purchaseRoute,
        component: BettingPurchase
    }
]

export default routes;