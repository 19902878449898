import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import color from "../../config/color";
import { useTranslation } from "react-i18next";
import logOutFirebase from "../../utils/firebaseLogout";
import { ref, set, onValue } from 'firebase/database';
import { db } from "../../config/firebase.config";
import { Form } from "react-bootstrap";
import { oddController } from "../../controllers/oddsController/oddController";
import { toast } from "react-toastify";

function NavBar({
  theme,
  history,
  location
}) {
  //const history = useHistory();
  const { t, i18n } = useTranslation("global");
  const [language, setLanguage] = useState("EN");
  const [isShow, setIsShow] = useState(false);
  const [format, setFormat] = useState();
  const [usePurchase, setUsePurchase] = useState();
  const [limitAmt, setLimitamt] = useState();
  const [comm, setComm] = useState();
  const [saveLoading,setSaveLoading] = useState(false);
  const [userInfo,setUserInfo] = useState(JSON.parse(localStorage.getItem("USR")));

  const handleRouteLink = (url) => {
    history.push(url);
  };

  const onChangeLanguage = () => {
    console.log("current lan", i18n.language)
    i18n.changeLanguage(i18n.language === "en" ? "mm" : "en");
    localStorage.setItem("language", i18n.language);
    setLanguage(i18n.language.toUpperCase());
  };

  const logout = () => {
    // set(ref(db,`loginUser/${username}`), {
    //    active: false
    // }).then(() => {
    // }).catch((error) => {
    //    console.log(error);
    // })
    try {
      //const dbRef = ref(db, `loginUser/${username}`);
      //set(dbRef, { active: false });
      localStorage.removeItem("USR");
      localStorage.removeItem("TOKEN");
      history.push('/');
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  useEffect(() => {
    //const userInfo = JSON.parse(localStorage.getItem("USR"));
    setFormat(userInfo.copyFormat);
    setUsePurchase(userInfo.usePurchaseMdule);
    setLimitamt(userInfo.limitAmount);
    setComm(userInfo.commission);
    //console.log("nav bar render",usePurchaseMdule)
  }, [])

  const handleSave = () => {
    setSaveLoading(true);
   // console.log("final paylaod", format, usePurchase, limitAmt, comm);
    const payload = {
      oddsUserId:userInfo.oddsUserId,
      commission:Number(comm),
      limitAmount:Number(limitAmt),
      usePurchaseMdule:usePurchase,
      copyFormat:format
    };

    oddController.updateSetting(payload, (data) => {
      setSaveLoading(false);
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      getSetting(userInfo.oddsUserId);
    });
  }

  const getSetting = (id) => {
    oddController.fetchSetting(id, (data) => {
      const oldData = localStorage.getItem("USR");
      const formattedData = JSON.parse(oldData);
      formattedData['copyFormat'] = data.payload.copyFormat; 
      formattedData['commission'] = data.payload.commission; 
      formattedData['usePurchaseMdule'] = data.payload.usePurchaseModule; 
      formattedData['limitAmount'] = data.payload.limitAmt;
      localStorage.setItem("USR", JSON.stringify(formattedData));
      setUserInfo(JSON.parse(localStorage.getItem("USR")))
    });
 
    setIsShow(false);
    console.log("pathname",location.pathname)
    if(location.pathname === "/analysis") window.location.reload();
  }

  return (
    <div>
      <div className="odds-nav" style={{ backgroundColor: color[theme].main }}>
        <div className="profile" style={{ fontSize: '0.87rem' }}>
          <img
            src="https://annedece.sirv.com/Images/user-vector.jpg"
            className="img-thumbnail rounded-circle"
            width={45}
            height={45}
          />
          <span className="info">{userInfo.oddsUserName}</span>
        </div>
        <div className="title">
          <i className="fa-solid fa-diamond diamond" style={{ fontSize: 12 }}></i>
          <span className="px-2">Odds Trasfer Site</span>
          <i className="fa-solid fa-diamond diamond" style={{ fontSize: 12 }}></i>
        </div>
        <div className="logout" style={{ display: 'flex', alignItems: 'flex-start', gap: 15 }}>
          {
            userInfo.oddsUserName === "paingpaing"  ? navbarItemList.map((v, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: color[theme].secondary,
                    cursor: 'pointer',
                  }}
                  onClick={v.name === "Language" ? () => onChangeLanguage() : () => setIsShow(prev => !prev)}
                >
                  <i
                    className={`fa-solid ${v.icon} lang-ico`}
                  ></i>
                  <span className="lang-title">{v.name}</span>
                  <span className="lang-value">{v.name === "Language" ? language : ""}</span>
                </div>
              )
            }
            ) :
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: color[theme].secondary,
                cursor: 'pointer'
              }}
                onClick={() => onChangeLanguage()}
              >
                <i
                  className="fa-solid fa-globe lang-ico"
                >
                </i>
                <span className="lang-title">Language</span>
                <span className="lang-value">{language}</span>
              </div>
          }
          <div style={{
            zIndex: 3,
            border: `1px solid ${color[theme].main}`,
            padding: '10px',
            position: 'absolute',
            top: '8%',
            right: '5%',
            background: 'whitesmoke',
            width: '200px',
            display: isShow ? "block" : "none",
            borderRadius: 5
          }}>
            <div style={{
              position: 'absolute',
              top: 0,
              right: 0,
              marginRight: 10,
              cursor:'pointer'
            }}>
              <i className="fa-solid fa-xmark" onClick={() => setIsShow(false)}></i>
            </div>
            <div className="mb-1">
              <label htmlFor="exampleInputEmail1" className="form-label" style={{ fontSize: '0.8rem' }}>
                {t('copyFormat')}
              </label>
              <div key={`inline-radio`} className="d-flex">
                <Form.Check
                  inline
                  value="Over"
                  style={{ fontSize: '0.78rem' }}
                  label={t('format1')}
                  checked={format === "F#1"}
                  name="over-odds"
                  type="radio"
                  id={`inline-radio-over`}
                  onChange={() => setFormat("F#1")}
                />
                <Form.Check
                  inline
                  value="Under"
                  style={{ fontSize: '0.78rem' }}
                  label={t('format2')}
                  name="under-odds"
                  checked={format === "F#2"}
                  type="radio"
                  id={`inline-radio-under`}
                  onChange={() => setFormat("F#2")}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-1">
              <input
                type="checkbox"
                style={{ fontSize: '0.8rem', marginRight: 5 }}
                checked={usePurchase}
                onChange={(v) => setUsePurchase(prev => !prev)}
                id="five"
              />
              <label className="form-check-label" htmlFor="five" style={{ fontSize: '0.78rem' }}>
                {t('bettingPurchase')}
              </label>
            </div>
            <div className="mb-1">
              <label htmlFor="exampleFormControlInput1" className="form-label" style={{ fontSize: '0.8rem', marginBottom: 0 }}>
              {t('differentLimit')}
              </label>
              <input
                type="number"
                value={limitAmt}
                className="custom-input"
                id="exampleFormControlInput1"
                onChange={(e) => setLimitamt(e.target.value)}
                style={{ fontSize: '0.8rem', height: 20 }}
              />
            </div>
            <div className="mb-1">
              <label htmlFor="exampleFormControlInput1" className="form-label" style={{ fontSize: '0.8rem', marginBottom: 0 }}>
              {t('commission')}
              </label>
              <input
                type="number"
                value={comm}
                className="custom-input"
                id="exampleFormControlInput2"
                onChange={(e) => setComm(e.target.value)}
                style={{ fontSize: '0.8rem', height: 20 }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                disabled={saveLoading}
                className="btn btn-light btn-sm"
                onClick={() => handleSave()}
                style={{ backgroundColor: color[theme].main, color: '#fff', fontSize: '0.8rem' }}
              >
                <span style={{ fontSize: '0.8rem' }}>
                {saveLoading ? t('saving') : t('save')}
                </span>
              </button>
            </div>
          </div>
          <button
            className="btn logLink"
            style={{ backgroundColor: color[theme].secondary, fontSize: '0.87rem' }}
            onClick={logout}
          >
            <i className="fa-solid fa-right-from-bracket"></i>
            <span className="loggingOut">&nbsp;{t('logOut')}</span>
          </button>
        </div>
      </div>
      {userInfo.roleId <= 2 ?
        <div
          className="group-list"
          role="group"
          aria-label="Basic mixed styles example"
        >
          {
            navbarItemArr.map((el, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  style={{ display: el.name === "purchaseReport" ? userInfo.usePurchaseMdule === true ? "block" : "none" : "block" }}
                  className={`btn ${location.pathname === el.link ? 'link-btn-active' : 'link-btn'}`}
                  onClick={() => handleRouteLink(el.link)}
                >
                  <span className="btn-label">{t(el.name)}</span>
                </button>
              )
            })
          }
        </div> : null
      }
      <hr />
    </div>
  );
}

export default NavBar;

const navbarItemList = [
  {
    name: "Language",
    icon: "fa-globe"
  },
  {
    name: "Setting",
    icon: "fa-screwdriver-wrench"
  }
]

const navbarItemArr = [
  {
    "name": "transfer",
    "link": "/odds"
  },
  {
    "name": "betting",
    "link": "/betting"
  },
  {
    "name": "report",
    "link": "/report"
  },
  {
    "name": "calculate",
    "link": "/calculate"
  },
  {
    "name": "outstanding",
    "link": "/history"
  },
  {
    "name": "customer",
    "link": "/customer"
  },
  {
    "name": "analysis",
    "link": "/analysis"
  },
  {
    "name": "purchaseReport",
    "link": "/purchase"
  }
]


