import React, { useEffect, useState } from 'react'
import './Login.css';
import { toast } from "react-toastify";
import Spinner from "../asset/spinner1";
import { useHistory } from "react-router-dom";
import { oddController } from '../controllers/oddsController/oddController';
import color from '../config/color';
import { ref, set,onValue } from 'firebase/database';
//import app from '../config/firebase.config';
import { logDOM } from '@testing-library/react';
import { use } from 'i18next';
import { db } from '../config/firebase.config';

function LogIn() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const history = useHistory();
    const [firebaseUsers,setFirebaseUsers] = useState([]);

      useEffect(()=>{
          if (localStorage.getItem("USR") !== null) {
              console.log(localStorage.getItem("USR"));
              history.push("/odds");
          }
          else {
              onValue(ref(db, "loginUser/"), snapshot => {
                  const data = snapshot.val();
                  console.log('data', data)
                  if (data) {
                      setFirebaseUsers(data);
                  }
              })
          }
        },[])


    const saveDataToFirebase = async (username) => {
        set(ref(db,`loginUser/${username}`), {
            active: true
        }).then(() => {
        }).catch((error) => {
            console.log(error);
        })
    }

    const handleSave = async () => {
        if (name === "" || password === "") {
            toast.error("Please fill information!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        setLoading(true);  
            oddController.checkLogIn(name, password, (data) => {
                setLoading(false);
                if (data.status === 1) {
                    toast.error(data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }
                if (data.status === 2) {
                    // const userInfo = firebaseUsers[data.userDetails.oddsUserName];
                    // console.log("userinfo", userInfo, data.userDetails.oddsUserName)
                    // if (userInfo && userInfo.active) {
                    //     toast.error("User is already logged in", {
                    //         position: toast.POSITION.TOP_RIGHT,
                    //     });
                    //     setLoading(false);
                    //     return;
                    // }
                    
                    localStorage.setItem("TOKEN", data.token);
                    localStorage.setItem("USR", JSON.stringify(data.userDetails));
                   
                    //saveDataToFirebase(name?.trim());
    
                    if (data.status == 2) {
                        history.push("/odds");
                    }

                    toast.success(data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
    }

    return (
        <div className="demo-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12 mx-auto">
                        <div className="text-center image-size-small position-relative">
                            <img src="https://annedece.sirv.com/Images/user-vector.jpg" className="rounded-circle p-2 bg-white" />
                            {/* <div className="icon-camera">
                                <a href="" className="text-primary"><i className="lni lni-camera"></i></a>
                            </div> */}
                        </div>
                        <div className="bg-white rounded shadow-lg login-panel">
                            <span className="mb-2 login-title">LOG IN</span>
                            <p className="text-center login-sub-title">Log In to manage all your process</p>
                            <form>
                                <label className="login-label">Username</label>
                                <div className="Icon-outside">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <input
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="custom-input mb-3 txt"
                                        type="text"
                                    />
                                </div>
                                <label className="login-label">Password</label>
                                <div className="Icon-outside1">
                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="custom-input"
                                        type="password"
                                    />

                                </div>
                                <p className="m-0 py-3"><a href="" className="text-muted"></a></p>
                                <button
                                    type="button"
                                    disabled={isLoading}
                                    style={{ backgroundColor: color['dark'].main, color: "#fff" }}
                                    className="btn btn-lg w-100 shadow-lg"
                                    onClick={() => handleSave()}
                                >
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {isLoading ? <Spinner /> : null}
                                        <span style={{ fontSize: "1rem" }}>
                                            LOG IN<i className="fa-solid fa-right-to-bracket" style={{ marginLeft: "0.4rem" }}></i>
                                        </span>
                                    </div>
                                </button>
                            </form>
                            {/* <div className="text-center pt-4">
                                <p className="m-0">Do not have an account? <a href="" className="text-dark font-weight-bold">Sign Up</a></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogIn