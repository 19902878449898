export const apiList = {
    getTeamApi : "Odd/get-teams",
    postSelectedTeamApi : "Odd/save-teams",
    updateOddsApi : "Odd/get-refresh-odd",
    copyTeamApi : "Odd",

    loginApi :"Odd/login",

    bettingEventApi : "Odd/get-betting-events",
    saveBettingEventApi : "Odd/save-betting-voucher",
    confirmSaveBettingEventApi : "Odd/save-betting-voucher-confirm",
    getCustomerApi : "Odd/get-customers",

    updateCustomerApi : "Odd/update-customers",
    saveCustomerApi : "Odd/add-customers",

    getMemberOutstandingApi:"Odd/get-memberoutstanding",
    updateMemberVoucherApi:"Odd/update-voucher",
    removeMemberVoucherApi:"Odd/remove-voucher",

    getWinLoseReportApi:"Odd/get-winlose-report",

    getEventResultApi :"Odd/get-event-result",
    calculateVoucherApi:"Odd/calculate-event-result",

    getSingleLiveDataApi : "Odd/single-live-data",
    getVoucherDetailApi : "Odd/analysis-voucher-details",
    getOverallVoucherApi : "Odd/voucher-overall",
    updateVoucherApi : "Odd/voucher-modify",

    bettingPurchaseApi : "Odd/purchased-voucher",

    settingApi : "Odd/setting"
};