// export default {
//     headerbackground: "#007C64",
//     secondarybackground:"#00706D",
//     color : "#fff",
//     primary:"#3b7197",
//     secondary:"#74bde0",
//     warning:"#F9F871",
//     light:"#0092B0",
//     lighter:"#00B3B3"
//   };

  //#156064
  //#00C49A
  //#F8E16C
  //#FFC2B4
  //#FB8F67

  //#006D77
  //#83C5be
  //#EDF6F9
  //#805D93
  //#E29578

  const color = {
    dark:{
        main : "#156064",
        secondBg : "whitesmoke",
        primary:"#00C49A",
        headerbg:"#badedb",
        primary1:"#83C5be",
        primary2:"#16f165",
        secondary:"#F8E16C",
        secondary1:"#FFC2B4",
        secondary2:"#FB8F67",
        secondary3:"#EDF6F9",
        secondary4:"#805D93",
        secondary5:"#bb7d0c",
        secondary6:"#e0001f",
        grayBg:"#dddddd87"
    }, 
    light:{
      main : "#156064",
      secondBg : "#000",
      headerbg:"#83c5be91",
    }
  }

  export default color;

  