import { useEffect, useState } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import color from './config/color';
import routes, { routeName } from './routes';
import NavBar from './Page/components/NavBar';

function App() {
  const { i18n } = useTranslation("global");
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const userInfo=localStorage.getItem("USR");

  useEffect(() => {
    //eslint-disable-next-line
    i18n.changeLanguage(localStorage.getItem("language") === "mm" ? "mm" : "en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appRoutes = routes.map((v, i) => (
    <Route
      key={i}
      path={v.path}
      exact={v.exact}
      render={props => <v.component theme="dark" {...props} history={history}/>}
    />
  ));

  if ((userInfo === undefined || userInfo === null) && (pathname !== "/login"))
    return <Redirect to={routeName.loginRoute} />

  return (
    <div>
      <div className="App" style={{ background: color['dark'].secondBg }}>
        <ToastContainer />
        {pathname !== '/login' && pathname !== '/' && (
          <NavBar
            theme="dark"
            history={history}
            location={location}
          />
        )}
        <Switch>
          {appRoutes}
          <Redirect to={routeName.loginRoute} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
