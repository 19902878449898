import React, { Fragment, useEffect, useState } from 'react'
import GoalLiveData from './GoalLiveData';
import BodyLiveData from './BodyLiveData';
import { useHistory } from "react-router-dom";
import './analysis.css';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import color from "../config/color";
import { useTranslation } from 'react-i18next';
import PurchaseBettingModal from './components/PurchaseBettingModal';

function Analysis(props) {
    const {t,i18n} = useTranslation('global');
    const handle = useFullScreenHandle();
    //const history = useHistory();
    
    useEffect(() => {
        // const userName = localStorage.getItem("userName");
        // const userRole = localStorage.getItem("userRole");
        
        // if (userName == undefined || userRole > 2) {
        //     history.push("/");
        // }
    
        //getCustomer();
    }, []);

    return (
        <div>
            <button onClick={handle.enter} className='btn btn-light' style={{ float: 'right' }}>
                <i className="fa-solid fa-maximize" style={{ color: 'gray', fontSize: '1.2rem' }}></i>
            </button>

            <FullScreen handle={handle}>

            {/* <div className="d-flex justify-content-center">
            <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className={goalbtnColor} onClick={()=>handleOnClick('goal')}>Goal Live Data</button>
                <button type="button" className={bodybtnColor} onClick={()=>handleOnClick('body')}>Body Live Data</button>
            </div>
            </div> */}
            {/* {type == 'goal' ?  <GoalLiveData /> :  <BodyLiveData />} */}

                <div style={{ background: handle.active ? "#fff" : null, height: handle.active ? '100%' : null }}>
                <span className="site-header" style={{color:color['dark'].main}}>{t('analysis')}</span>
                    { handle.active ?
                        <button onClick={handle.exit} className='btn btn-light' style={{ position: 'absolute',top : 0 ,right : 0 }}>
                            <i className="fa-solid fa-minimize" style={{ color: 'gray', fontSize: '1.2rem' }}></i>
                        </button> : null
                    }
                   <BodyLiveData t={t} i18n={i18n} {...props} />
                </div>
            </FullScreen>
        </div>
    )
}

export default Analysis
