import React, { Fragment, useEffect, useState } from 'react';
import Loading from './components/Loading';
import { useTranslation } from "react-i18next";
import color from '../config/color';
import { oddController } from '../controllers/oddsController/oddController';
import moment from 'moment/moment';
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import PurchaseBettingModal from './components/PurchaseBettingModal';

const BettingPurchase = (props) => {
    const {theme,history} = props;
    const userInfo=JSON.parse(localStorage.getItem("USR"));
    const { t, i18n } = useTranslation("global");
    const [page, setPage] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const rowsPerPage = 7;
    const [pageCount, setPageCount] = useState(0);
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [voucherList, setVoucherList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [editData, seteditData] = useState(null);
    const [betTotal, setBetTotal] = useState();
    const [wlTotal, setWlTotal] = useState();
    const [comTotal, setComTotal] = useState();
    const [allTotal, setAllTotal] = useState();

    useEffect(() => {
        if(!userInfo.usePurchaseMdule){
            history.push('/login');
        }
        getVoucherList();
    }, []);

    const getVoucherList = () => {
        setLoading(true);
        oddController.getPurchasedVouchers(date, parseInt(userInfo.oddsUserId), (data) => {

            const betAmt = data.payload.reduce((accumulator, current) => accumulator + current.amount, 0);
            const wlAmt = data.payload.reduce((accumulator, current) => accumulator + current.winLoseAmount, 0);
            const com = data.payload.reduce((accumulator, current) => accumulator + current.commission, 0);

            setBetTotal(betAmt);
            setWlTotal(wlAmt);
            setComTotal(com);
            setAllTotal(wlAmt + com);

            setVoucherList(data.payload);
            setPage(Math.ceil(data.payload.length / rowsPerPage));
            setLoading(false);
        });
    }

    const handleSearch = () => {
        getVoucherList();
    };

    const handlePageClick = async (data) => {
        setPageCount(Math.ceil(data.selected));
    };

    const handleDelete = (id) => {
        setLoading(true);
        oddController.deletePurchasedVoucher(parseInt(id), (data) => {
            setLoading(false);
            toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            getVoucherList();
        });
    }

    const handleEdit = (data) => {
        seteditData(data);
        const obj = {
            rapidEventId: data.rapidEventId,
            homeTeamId: data.isHome || data.isAway ? data.isHome ? data.footballTeamId : data.oppositeTeamId : (data.isOver || data.isUnder) ? data.footballTeamId : null,
            awayTeamId: data.isHome || data.isAway ? data.isAway ? data.footballTeamId : data.oppositeTeamId : (data.isOver || data.isUnder) ? data.oppositeTeamId : null,
            homeTeamMyan: data.isHome || data.isAway ? data.isHome ? data.footballTeamMyan : data.oppositeTeamMyan : (data.isOver || data.isUnder) ? data.footballTeamMyan : null,
            awayTeamMyan: data.isHome || data.isAway ? data.isAway ? data.footballTeamMyan : data.oppositeTeamMyan : (data.isOver || data.isUnder) ? data.oppositeTeamMyan : null,
            homeTeam: data.isHome || data.isAway ? data.isHome ? data.footballTeam : data.oppositeTeam : (data.isOver || data.isUnder) ? data.footballTeam : null,
            awayTeam: data.isHome || data.isAway ? data.isAway ? data.footballTeam : data.oppositeTeam : (data.isOver || data.isUnder) ? data.oppositeTeam : null,
            //underId: data.oddType === "",
            //overId: d.overTeamId
        }
        setEventList([obj]);
    }

    return (
        <div>
            <PurchaseBettingModal 
            t={t} 
            userId={userInfo.oddsUserId} 
            eventList={eventList} 
            editData={editData} 
            seteditData={seteditData} 
            i18n={i18n} 
            handleFetch={getVoucherList} 
            theme={theme}
            />
            <div>
                <span className="site-header" style={{ color: color[theme].main }}>{t('purchaseReport')}</span>
                <div className="customer-header">
                    <div className="d-flex">
                        <div className="bd-highlight">
                            <div className="mb-2">
                                <input
                                    className="form-control"
                                    type="date"
                                    id="birthday"
                                    value={date}
                                    style={{ fontSize: '0.8rem' }}
                                    onChange={(e) => setDate(e.target.value)}
                                    name="goal-calculate"
                                />
                            </div>
                        </div>
                        <div className="bd-highlight">
                            <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: color[theme].main, color: '#fff', fontSize: '0.8rem', marginLeft: 5 }}
                                onClick={() => handleSearch()}
                            >
                                {t('search')}
                            </button>
                        </div>
                    </div>
                    {/* <button
                                type="button"
                                className="btn"
                                //disabled={eventList.length === 0}
                                style={{ backgroundColor: color[theme].main, color: '#fff', fontSize: '0.8rem' }}
                                data-bs-target="#purchaseBettingModal"
                                data-bs-toggle="modal"
                            >
                                <i className="fas fa-add my-icon"></i>&nbsp;{t('addNew')}
                            </button>  */}
                </div>
                {isLoading ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Loading />
                        <p>Loading .....</p>
                    </div>
                ) : (
                    <Fragment>
                        <div className="table-responsive">
                            <table className="table">
                                <thead style={{ fontSize: '0.85rem', backgroundColor: color[theme].headerbg }}>
                                    <tr>
                                        <th scope="col">{t('no')}</th>
                                        <th scope="col">{t('bettedDate')}</th>
                                        <th scope="col">{t('choice')}</th>
                                        <th scope="col">{t('odds')}</th>
                                        <th scope="col">{t('betAmt')}</th>
                                        <th scope="col">{t('wlAmount')}</th>
                                        <th scope="col">{t('commission')}</th>
                                        <th scope="col">{t('totalAmount')}</th>

                                        <th scope="col">{t('status')}</th>
                                        <th scope="col">{t('remark')}</th>
                                        <th scope="col">{t('action')}</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.8rem' }}>
                                    {voucherList.length != 0 ?
                                        voucherList &&
                                        voucherList
                                            .slice(
                                                pageCount * rowsPerPage,
                                                pageCount * rowsPerPage + rowsPerPage
                                            )
                                            .map((d, i) => {
                                                return (
                                                    <tr key={d.bettingPurchaseId}>
                                                        <th scope="row">{pageCount * rowsPerPage + i + 1}</th>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <span>{moment(d.createdAt).format("YYYY-MM-DD hh:mma")}</span>
                                                                {/* <span>{moment(d.createdAt).format("hh:mm a")}</span> */}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {d.isOver ?
                                                                <span style={{ color: 'red' }}>{i18n.language === "mm" ? d.footballTeamMyan : d.footballTeam}({t('over')})</span>
                                                                : d.isUnder ?
                                                                    <span style={{ color: 'green' }}>{i18n.language === "mm" ? d.footballTeamMyan : d.footballTeam}({t('under')})</span>
                                                                    : <span style={{ color: d.oddsType === "Over" ? 'red' : 'green' }}>{i18n.language === "mm" ? d.footballTeamMyan : d.footballTeam}</span>}
                                                        </td>
                                                        <td>{d.isHome || d.isAway ? d.bodyOdds : d.goalOdds}</td>
                                                        <td>{d.amount}</td>
                                                        <td><span style={{ color: d.winLoseAmount < 0 ? 'red' : 'black' }}>{d.winLoseAmount}</span></td>
                                                        <td>{d.commission}</td>
                                                        <td><span style={{ color: d.winLoseAmount < 0 ? 'red' : 'black' }}>{d.winLoseAmount + d.commission}</span></td>

                                                        <td>{d.status === "pending" ?
                                                            <span className='badge bg-warning text-dark' style={{ width: 50, fontSize: 10 }}>{d.status}</span> :
                                                            d.status === "Win" ?
                                                                <span className='badge bg-success' style={{ width: 50, fontSize: 10 }}>{d.status}</span> :
                                                                <span className='badge bg-danger' style={{ width: 50, fontSize: 10 }}>{d.status}</span>
                                                        }
                                                        </td>
                                                        <td>{d.remark}</td>
                                                        <td>
                                                            {d.status === "pending" ?
                                                                <>
                                                                    <button
                                                                        className="btn"
                                                                        style={{
                                                                            backgroundColor: color[theme].main,
                                                                            color: '#fff',
                                                                            fontSize: '0.75rem'
                                                                        }}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#purchaseBettingModal"
                                                                        onClick={() =>
                                                                            handleEdit(d)
                                                                        }
                                                                    >
                                                                        <i className="fas fa-edit my-icon"></i>&nbsp;{t('edit')}
                                                                    </button>
                                                                    <button
                                                                        className="btn"
                                                                        style={{
                                                                            backgroundColor: color[theme].secondary6,
                                                                            color: '#fff',
                                                                            fontSize: '0.75rem',
                                                                            marginLeft: 3
                                                                        }}
                                                                        onClick={() =>
                                                                            handleDelete(d.bettingPurchaseId)
                                                                        }
                                                                    >
                                                                        <i className="fas fa-trash my-icon"></i>&nbsp;{t('delete')}
                                                                    </button>
                                                                </> : null
                                                            }
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                        :
                                        <tr>
                                            <td colSpan={11} style={{ textAlign: 'center' }}>{t('nodata')}</td>
                                        </tr>
                                    }
                                    {
                                        voucherList.length != 0 ?
                                            <tr style={{ fontWeight: 'bold', background: color[theme].grayBg, fontSize: '0.8rem' }}>
                                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                                    All Total :
                                                </td>
                                                <td>
                                                    {betTotal}
                                                </td>
                                                <td>
                                                    <span style={{color:wlTotal < 0 ? 'red' : 'black'}}>{wlTotal.toFixed(2)}</span>
                                                </td>
                                                <td>
                                                <span style={{color:comTotal < 0 ? 'red' : 'black'}}>{comTotal.toFixed(2)}</span>
                                                </td>
                                                <td style={{ textAlign: 'left' }} colSpan={4}>
                                                <span style={{color:allTotal < 0 ? 'red' : 'black'}}>{allTotal.toFixed(2)}</span>
                                                </td>
                                            </tr> : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <ReactPaginate
                            previousLabel={t('previous')}
                            nextLabel={t('next')}
                            breakLabel={"..."}
                            pageCount={page}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </Fragment>
                )
                }
            </div>
        </div>
    )
}

export default BettingPurchase;
